import MainUserComponent from '../mainsUsersComponent';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Bronze Users',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'tagged-users',
  components: {
    MainUserComponent
  },
  data() {
    return {
      parent: {
        name: 'UserSegments',
        dispatchName: 'admin/users/getUserSegments',
        searchDispatchName: 'admin/users/searchUserSegments',
        category: {
          category: 3,
          page: 1
        }
      }
    };
  }
};